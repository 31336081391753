import React from 'react';
import DigitalCardExampleImage from "../../../../../assets/img/digital_card_example.png";

export default function DigitalCardExample({ className = "", width = "", height = "" }) {
    return (
        <img
            src={DigitalCardExampleImage}
            alt="digital-card-example"
            style={width || height ? { width, height, objectFit: "cover" } : { maxWidth: "100%" }}
            className={className}
        />
    );
}
