import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import close_x from "../../assets/svg/close_x.svg";
import search_icon from "../../assets/svg/search_icon.svg";
import useDebounce from "../../hooks/useDebounce";
import { useEvent } from "../../hooks/useEvent";
import { useSearch } from "../../hooks/useSearch";
import "./index.css";
import useWindowSize from "../../hooks/useWindowSize";
import { transformStateName } from "../../services/constants";
// import { useLocation } from "../../hooks/useLocation";
import { useTranslation } from "react-i18next";

export const handleSelectedStateStorage = (state) => {
  if (state) {
    localStorage.setItem("selectedState", state);
  } else {
    localStorage.removeItem("selectedState");
  }
};

export const cleanUrl = () => {
  const currentURL = window.location.href;
  let url = new URL(currentURL);
  url.searchParams.delete("st");
  window.history.pushState({ path: url.href }, "", url.href);
};

function LocationModal({ show, onClose, onLocationSelected, api }) {
  const { events } = useEvent();
  const { updateSearch } = useSearch();
  // const { handleIPLocationError } = useLocation();
  const isMobile = useWindowSize();
  const [states, setStates] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [statesLoading, setStatesLoading] = useState(false);
  const [searchedStates, setSearchedStates] = useState([]);
  const history = useHistory();
  const debounceValue = useDebounce(searchInput, 500);
  const { t } = useTranslation();

  const fetchStates = async (events, setStates, setSearchedStates) => {
    let statesData = [];

    if (events && events.states) {
      statesData = events.states.map((abbreviation) =>
        transformStateName(abbreviation)
      );
    } else if (window.location.pathname === "/eventos") {
      const { data } = await api.get("event-locations/best-states");
      statesData = data.states.map((acronym) => transformStateName(acronym));
    }

    setStates(statesData);
    setSearchedStates(statesData);
  };

  useEffect(() => {
    if (states.length === 0) {
      fetchStates(events, setStates, setSearchedStates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, states.length]);

  const closeModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const redirectUser = () => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/eventos"
    ) {
      history.push("/");
      window.location.reload();
    }
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleInputSearch = (value) => {
    setStatesLoading(true);

    if (searchInput === "") {
      setSearchedStates(states);
      setStatesLoading(false);
      return;
    }

    const filteredStates = states.filter((state) => {
      if (state) {
        const cleanupState = removeAccents(state);
        return cleanupState
        .toLowerCase()
        .includes(removeAccents(value.toLowerCase()));
        }
      return states
    });
    setSearchedStates(filteredStates);
    setStatesLoading(false);
  };

  useEffect(() => {
    if (debounceValue) {
      handleInputSearch(debounceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue, searchInput]);

  const handleSelectedState = (state) => {
    redirectUser();

    if (!state) {
      setStates([]);
      updateSearch("state", null);
      onLocationSelected(null);
      localStorage.setItem("selectedState", "Todos os estados");
      cleanUrl();
    } else {
      updateSearch("state", state);
      const newURL = `?st=${state}`;
      window.history.pushState({ path: newURL }, "", newURL);
      onLocationSelected(state);
      handleSelectedStateStorage(state);
    }
    closeModal();
  };

  return (
    <Fragment>
      <Modal className="location-modal" show={show} onHide={closeModal}>
        <Modal.Header>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center location-title">
              <Modal.Title
                style={{ color: "#12263F" }}
                className="new-modal-title"
              >
                {t("LocationModal.location")}
              </Modal.Title>
              <button
                onClick={closeModal}
                type="button"
                className="close"
                aria-label="Close"
              >
                <img
                  src={close_x}
                  alt="close icon"
                  className="x-close"
                  aria-hidden="true"
                ></img>
              </button>
            </div>
            <div>
              <div className="input-city-search">
                <span
                  className={`input-group-text custom-span ${
                    isFocused ? "focused" : ""
                  }`}
                >
                  <img src={search_icon} alt="search icon" />
                </span>
                <input
                  type="text"
                  className="form-control custom-input"
                  id="main-bar-search"
                  placeholder={t("LocationModal.searchState")}
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                  onFocus={() => {
                    setTimeout(() => setIsFocused(true), 80);
                  }}
                  onBlur={() => {
                    setTimeout(() => setIsFocused(false), 40);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          className="modal-flow overflow-auto"
          style={{ padding: 0, maxHeight: isMobile ? "auto" : 400 }}
        >
          {statesLoading ? (
            <div
              style={{ width: "1rem", height: "1rem" }}
              class="justify-content-start spinner-border spinner-border"
              role="status"
            >
              <span class="sr-only">{t("CommonExpressions.loading")}</span>
            </div>
          ) : (
            <ul className="states-list">
              {/* {
                <li
                  className="states-list-item"
                  onClick={() => {
                    handleIPLocationError(true);
                    closeModal();
                  }}
                >
                  {t("LocationModal.useMyLocation")}
                </li>
              } */}
              <li
                className="states-list-item"
                key={"all"}
                onClick={() => handleSelectedState(null)}
              >
                {t("LocationModal.allStates")}
              </li>
              <div className="d-flex mobile"></div>
              {searchedStates.map((state, index) => (
                <li
                  className="states-list-item"
                  key={index}
                  onClick={() => handleSelectedState(state)}
                >
                  {state}
                </li>
              ))}
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default LocationModal;
