import React from "react";
import "./style.css";

export default function OrderValueSection({ type, status, value, code }) {
  return (
    <>
      <p className={`checkout-text ${status}-text`}>{value}</p>
      {type === "pix" && (
        <p id="code-pix" className={`checkout-text ${status}-text`}>
          {code}
        </p>
      )}
    </>
  );
}