import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.css";
import { SponsorCategoryDict } from "../../services/constants";
import ImageHandlerService from "../../services/image-handler";
import { group } from "../../Helpers/radash";

Sponsors.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
      logo_url: PropTypes.string,
      category: PropTypes.string,
      order: PropTypes.number,
    })
  ),
};

export default function Sponsors(props) {
  const { sponsors } = props;

  const [sponsorsGrouped, setSponsorsGrouped] = useState([]);

  useEffect(() => {
    const sortedSponsors = sponsors.sort((a, b) => {
      return a.order - b.order;
    });

    const sponsorsByCategory = group(sortedSponsors, (s) => s.category);

    setSponsorsGrouped(sponsorsByCategory);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-100">
        {Object.keys(sponsorsGrouped).map((objKey, index) => {
          const sponsorsArr = sponsorsGrouped[objKey];
          return (
            <div className="sponsor-group col align-self-center" key={index}>
              <p>{SponsorCategoryDict[objKey]}</p>

              <div className="sponsor-imgs">
                {sponsorsArr.map((sponsor, index) =>
                  sponsor.url ? (
                    <a
                      key={index}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={sponsor.url}
                    >
                      <div
                        className="sponsor-logo"
                        style={{
                          backgroundImage: `url(${ImageHandlerService.getImageFromUrl(
                            sponsor.logo_url,
                            { format: "webp" }
                          )})`,
                        }}
                        alt={`${sponsor.name} logo`}
                      />
                    </a>
                  ) : (
                    <div
                      key={index}
                      className="sponsor-logo"
                      style={{ backgroundImage: `url(${sponsor.logo_url})` }}
                      alt={`${sponsor.name} logo`}
                    />
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
