import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as GooglePlayBadge } from "../../assets/svg/google-play-badge.svg";
import { ReactComponent as AppleStoreBadge } from "../../assets/svg/apple-store-badge.svg";
import { ReactComponent as Tickets } from "../../assets/svg/tickets.svg";
import { ReactComponent as Beverage } from "../../assets/svg/beverage.svg";
import { ReactComponent as Party } from "../../assets/svg/party.svg";
import { pushGAEvent } from "../../Helpers/tagManager";
import DownloadAppModal from "../DownloadAppModal";
import devices from "../../assets/img/devices.png";
import "./styles.css";

function DownloadApp({ isOpen, handleOpenModal, seeTicket }) {
  const { t } = useTranslation();

  const handleRedirect = () => {
    pushGAEvent("clk_banner_download_app");
    window.location.href = "https://zigpay.page.link/download";
  };

  const handleOpen = () => {
    pushGAEvent("clk_banner_download_app");
    handleOpenModal();
  };

  const isMobile = () => {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  };

  return (
    <>
      <div
        className="container download-app d-flex flex-column flex-lg-row justify-content-between px-4 pt-4 pb-lg-4"
        onClick={isMobile() ? handleRedirect : handleOpen}
      >
        <div className="central-text">
          <div
            className="d-flex flex-column align-items-lg-start px-4 px-lg-0"
            style={{ gap: "0.5rem" }}
          >
            <h5 className="text-lg-left">
              {t("DownloadApp.liveUniqueExperiences")}
            </h5>
            <div
              className="phrases d-flex flex-column flex-lg-row align-items-lg-start"
              style={{ gap: "0.5rem" }}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.5rem" }}
              >
                <div>
                  <Tickets stroke="#0050C3" width={20} height={20} />
                </div>
                <div>{t("DownloadApp.yourTicketInYourMobile")}</div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.5rem" }}
              >
                <div>
                  <Beverage width={20} height={20} />
                </div>
                <div>{t("DownloadApp.payYourConsumption")}</div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.5rem" }}
              >
                <div>
                  <Party width={22} height={22} />
                </div>
                <div>{t("DownloadApp.zigGoesWithYou")}</div>
              </div>
            </div>
            <div
              className="d-flex flex-column align-items-center align-items-lg-start"
              style={{ gap: "1rem" }}
            >
              {isMobile() ? (
                <div className="download-app-buttons d-flex">
                  <GooglePlayBadge
                    onClick={handleRedirect}
                    style={{ width: "132px", height: "40px" }}
                  />
                  <AppleStoreBadge
                    onClick={handleRedirect}
                    style={{ width: "132px", height: "40px" }}
                  />
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-sm download-now"
                  type="button"
                  style={{ gap: "10px" }}
                  onClick={handleOpen}
                  data-toggle="modal"
                >
                  {t("DownloadApp.downloadNow")}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="devices">
          <img src={devices} alt="devices" className="devices-image" />
        </div>
      </div>
      <DownloadAppModal
        isOpen={isOpen}
        handleClose={handleOpen}
        seeTicket={seeTicket}
      />
    </>
  );
}

export default DownloadApp;
