import React, { Fragment } from "react";
import QRCode from "react-qr-code";

export default function QRCodeSection({ code }) {
  return (
    <Fragment>
      {code && (
        <Fragment>
          <div>
            <div className="qrcode-container">
              <QRCode value={code} level="H" size={230} />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
