export const group = (array, getGroupId) => {
    return array.reduce((acc, item) => {
        const groupId = getGroupId(item);
        if (!acc[groupId])
            acc[groupId] = [];
        acc[groupId].push(item);
        return acc;
    }, {});
};

export const isObject = (value) => {
    return !!value && value.constructor === Object;
};

const isFunction = (value) => {
    return !!(value && value.constructor && value.call && value.apply);
};

export const isArray = Array.isArray

export const capitalize = (str) => {
    if (!str || str.length === 0)
        return "";
    const lower = str.toLowerCase();
    return lower.substring(0, 1).toUpperCase() + lower.substring(1, lower.length);
};
export const sift = (list2) => {
    return list2?.filter((x) => !!x) ?? [];
};

export const sort = (array, getter, desc = false) => {
    if (!array)
        return [];
    const asc = (a, b) => getter(a) - getter(b);
    const dsc = (a, b) => getter(b) - getter(a);
    return array.slice().sort(desc === true ? dsc : asc);
};

export const sleep = (milliseconds) => {
    return new Promise((res) => setTimeout(res, milliseconds));
};

export const objectify = (array, getKey, getValue = (item) => item) => {
    return array.reduce((acc, item) => {
        acc[getKey(item)] = getValue(item);
        return acc;
    }, {});
};

export const unique = (array, toKey) => {
    const valueMap = array.reduce((acc, item) => {
        const key = toKey ? toKey(item) : item;
        if (acc[key])
            return acc;
        acc[key] = item;
        return acc;
    }, {});
    return Object.values(valueMap);
};

export const list = (startOrLength, end, valueOrMapper, step) => {
    return Array.from(range(startOrLength, end, valueOrMapper, step));
};

function* range(startOrLength, end, valueOrMapper = (i) => i, step = 1) {
    const mapper = isFunction(valueOrMapper) ? valueOrMapper : () => valueOrMapper;
    const start = end ? startOrLength : 0;
    const final = end ?? startOrLength;
    for (let i = start; i <= final; i += step) {
        yield mapper(i);
        if (i + step > final)
            break;
    }
}