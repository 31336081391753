import React, { useState } from "react";
import { Accordion, Card, Row } from "react-bootstrap";
import { ReactComponent as Copy } from "../../../../assets/svg/pix_copy.svg";
import { ReactComponent as Tickets } from "../../../../assets/svg/my_tickets.svg";
import { ReactComponent as PixIcon } from "../../../../assets/svg/pix_icon.svg";
import { ReactComponent as Phone } from "../../../../assets/svg/smartphone.svg";
import { useTranslation } from "react-i18next";

export default function PixAccordion() {
  const [howToOpen, setHowToOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Card className={`checkout-card`}>
      <Card.Body id="instructions-content">
        <div className="d-flex justify-content-start pix-instructions">
          <Accordion className="summary-accordion">
            <Card id="pix-instructions">
              <Accordion.Toggle
                as={Card.Header}
                eventKey="howToPix"
                className={howToOpen ? "card-header-opened" : ""}
                onClick={() => setHowToOpen(!howToOpen)}
              >
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span className="how-to">
                    <strong>{t("CheckoutStatus.accordion.pix.title")}</strong>
                  </span>
                  <span className="summary-total acc-result-arrow"></span>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse id="acc-col-result" eventKey="howToPix">
                <Card.Body className="d-flex flex-column align-items-start text-left">
                  <Row className="how-to ml-2 mb-4 d-flex flex-row align-items-center justify-content-center flex-nowrap pix-accordion-item">
                    <Copy
                      className="mr-2"
                    />
                    <p>{t("CheckoutStatus.accordion.pix.firstStep")}</p>
                  </Row>
                  <Row className="how-to ml-2 mb-4 d-flex flex-row align-items-center justify-content-center flex-nowrap pix-accordion-item">
                    <Phone
                      className="mr-2"
                    />
                    <p>{t("CheckoutStatus.accordion.pix.secondStep")}</p>
                  </Row>
                  <Row className="how-to ml-2 mb-4 d-flex flex-row align-items-center justify-content-center flex-nowrap pix-accordion-item">
                    <PixIcon
                      className="mr-2"
                    />
                    <p>{t("CheckoutStatus.accordion.pix.thirdStep")}</p>
                  </Row>
                  <Row className="how-to ml-2 mb-2 d-flex flex-row align-items-center justify-content-center flex-nowrap pix-accordion-item">
                    <Tickets
                      className="mr-2"
                    />
                    <p>{t("CheckoutStatus.accordion.pix.fourthStep")}</p>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  );
}
