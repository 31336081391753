import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Export } from "../../../../../assets/svg/external.svg";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";
import { getSessionStorageItems } from "../../../../../services/utils";
import Countdown from "react-countdown";
import "./style.css";

export default function PicpaySection({ code, handleRedirect, handlePayment }) {
  const { t } = useTranslation();
  const [showPaidButton, setShowPaidButton] = useState(false);
  const [timer, setTimer] = useState(false);
  const { paymentDate } = getSessionStorageItems(["payment_date"]);

  useEffect(() => {
    const interval = setInterval(async () => {
      setShowPaidButton(true);
    }, 40000);

    const parsedPaymentDate = new Date(JSON.parse(paymentDate));
    parsedPaymentDate.setTime(parsedPaymentDate.getTime() + 3600000);
    setTimer(parsedPaymentDate);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !!code && (
    <Fragment>
      <div className="d-flex w-md-100 justify-content-center align-items-center gap-2">
        <NewButton
          className="col-12 col-md-6 primary-contained pic-pay-btn"
          onClick={() => handleRedirect("picpay")}
          content={
            <Fragment>
              {t("CheckoutStatus.buttons.picpay")}{" "}
              <Export stroke="white" />
            </Fragment>
          }
        />
        {showPaidButton && (
          <NewButton
            className="col-12 col-md-6 secondary-contained pic-pay-btn"
            onClick={handlePayment}
            content={<Fragment>{t("CheckoutStatus.common.paymentDone")}</Fragment>}
          />
        )}
      </div>
      <div>
        {timer && (
          <div className="col-12 d-flex flex-row justify-content-center align-items-center expire-code">
            <p>{t("CheckoutStatus.common.codeExpire")}
              <strong>
                {" "}<Countdown daysInHours zeroPadTime={2} date={timer} />
              </strong>
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
}
