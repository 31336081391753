import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import MaskHelper from "../../../Helpers/mask";

export const FillWithMyData = ({
  translate,
  onSetValue,
  onZipCodeFetched,
  user,
  clearErrors,
}) => {
  const handlePaymentUserFill = (isChecked) => {
    if (isChecked) {
      onSetValue("payment.document", MaskHelper.cpfMask(user.document));
      onSetValue("payment.phone", MaskHelper.phoneMask(user.telephone));

      if (user.address?.length > 0) {
        onSetValue("payment.zip_code", user.address.zip_code);
        onSetValue("payment.street", user.address.street);
        onSetValue("payment.state", user.address.state);
        onSetValue("payment.neighborhood", user.address.neighborhood);
        onSetValue("payment.number", user.address.number);
        onSetValue("payment.city", user.address.city);
        onZipCodeFetched(true);
      }
    } else {
      onSetValue("payment.document", "");
      onSetValue("payment.phone", "");
      onSetValue("payment.zip_code", "");
      onSetValue("payment.street", "");
      onSetValue("payment.state", "");
      onSetValue("payment.neighborhood", "");
      onSetValue("payment.number", "");
      onSetValue("payment.city", "");
    }

    clearErrors("payment.document");
    clearErrors("payment.phone");
    clearErrors("payment.zip_code");
    clearErrors("payment.street");
    clearErrors("payment.state");
    clearErrors("payment.neighborhood");
    clearErrors("payment.number");
    clearErrors("payment.city");
  };

  useEffect(() => {
    handlePaymentUserFill(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label={translate("Checkout.fillWithMyData")}
            className="input-autofill"
            inline
            id="payment_autofill"
            defaultChecked={true}
            onChange={(e) => handlePaymentUserFill(e.target.checked)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};
