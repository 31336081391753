import React, { useState } from "react";
import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { animateScroll as scroll } from "react-scroll";

import MarketplaceTicketApi from "../../../services/marketplace-ticket";
import NominalTransferSuccessModal from "../../../components/SuccessNominalTransferModal";

import { SomethingUnplannedHappenedModal } from "../../../components/SomethingUnplannedHappened";
import { ReactComponent as TicketExhibit } from "../../../assets/svg/ticket-exhibit.svg";
import { ReactComponent as PaperPlane } from "../../../assets/svg/paper-plane.svg";
import { ReactComponent as Return } from "../../../assets/svg/return.svg";

const marketplaceTicketApi = new MarketplaceTicketApi();

export default function NominalTransferSecondScreen({
  handleScreenChange,
  ticketName,
  eventName,
  ticketId,
  data,
}) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const { t } = useTranslation();
  const history = useHistory();

  const handleTransfer = async (t) => {
    setLoadingTransfer(true);

    await marketplaceTicketApi
      .post(`api/v1/ticket-transfers`, { ticketId, email: data.receiverEmail })
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          setErrorMessage(t("NominalTransfer.errorBadRequest"));
        } else if (error?.response?.status === 500) {
        } else if (error?.response?.status === 404) {
          if (
            error.response.data.message.includes("Not accepted transfer Ticket")
          ) {
            setErrorMessage(t("NominalTransfer.errorUnableToTransfer"));
            setErrorTitle(t("NominalTransfer.errorUnableToTransferTitle"));
          } else if (
            error.response.data.message.includes(
              "The transfer can only be carried out until the date"
            )
          ) {
            setErrorMessage(t("NominalTransfer.errorInvalidDate"));
            setErrorTitle(t("NominalTransfer.errorInvalidDateTitle"));
          } else {
            setErrorMessage(t("NominalTransfer.errorNotFound"));
          }
        }
        setShowErrorModal(true);
      })
      .finally(() => {
        setLoadingTransfer(false);
      });
  };

  return (
    <div className="pb-md-5">
      <div className="body d-flex flex-column justify-content-center align-items-center">
        <h3>{t("NominalTransfer.reviewData")}</h3>

        <div className="data-review-container d-flex flex-column py-3">
          <div className="ticket-data-review">
            <div className="d-flex flex-column ticket-name-event-container">
              <p className="ticket-name tl-ellipsis">{ticketName}</p>
              <p className="ol-ellipsis">{eventName}</p>
            </div>
          </div>
          <div>
            <hr className="mx-3 my-0" id="nominal-transfer-hr" />
          </div>

          <div
            className="d-flex flex-column ticket-data-review"
            style={{ gap: "16px" }}
          >
            <div className="d-flex flex-column">
              <p>
                <span className="font-weight-bold">{t("Checkout.email")}:</span>{" "}
                {data.receiverEmail}
              </p>
            </div>
            <Button
              type="button"
              id="edit-email"
              variant="secondary"
              className="edit-email"
              onClick={() => {
                handleScreenChange(1);
                scroll.scrollToTop();
              }}
            >
              {t("NominalTransfer.editEmail")}
            </Button>
          </div>
        </div>

        <div className="d-flex flex-column w-100 redeem-instructions-container py-4 px-3">
          <div className="d-flex flex-row">
            <div className="d-flex align-items-center justify-content-center py-1 pr-2">
              <TicketExhibit width={20} height={20} color="#0050C3" />
            </div>
            <div>
              <span className="redeem-instructions-text">
                {t("NominalTransfer.redeemInstructions")}
              </span>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className="d-flex align-items-center justify-content-center py-1 pr-2">
              <Return width={20} height={20} />
            </div>
            <div>
              <span className="redeem-instructions-text">
                {t("NominalTransfer.cantUndo")}
              </span>
            </div>
          </div>
        </div>

        <div className="buttons-container d-flex flex-column flex-lg-row-reverse w-100">
          <Button
            id="transfer-ticket"
            className="ticket-transfer-button d-flex px-4 py-3 align-items-center justify-content-center w-100"
            onClick={() => handleTransfer(t)}
            disabled={loadingTransfer}
          >
            {t("NominalTransfer.transferTicket")}
            <PaperPlane width={20} height={20} />
          </Button>

          <Button
            id="cancel"
            type="button"
            variant="secondary"
            className="cancel-transfer px-4 py-3 w-100"
            onClick={() => history.push("/meus-ingressos")}
          >
            {t("NominalTransfer.cancelTransfer")}
          </Button>
        </div>
      </div>

      <NominalTransferSuccessModal
        show={showSuccessModal}
        onClose={setShowSuccessModal}
        eventName={eventName}
        recipientsMail={data.receiverEmail}
      />

      <SomethingUnplannedHappenedModal
        show={showErrorModal}
        onClose={setShowErrorModal}
        message={errorMessage}
        title={errorTitle}
      />
    </div>
  );
}
