import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export const YunoView = ({ isZig, orderData, walletMethods, watch }) => {
  return (
    <div
      className={
        isZig() &&
        orderData?.value > 0 &&
        !walletMethods.includes(watch("payment.payment_type"))
          ? "d-block"
          : "d-none"
      }
    >
      <div id="yuno-payment-form">
        <div className="loader-icon text-center">
          <FontAwesomeIcon icon={faCircleNotch} id="loading-icon" />
        </div>
      </div>
    </div>
  );
};
