import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import QRCode from "react-qr-code";

import './styles.css'

export default function RechargeDigitalCardModal({ isOpen, handleClose }) {
    const { t } = useTranslation();

    const RECHARGE_MOBILE_URL = "https://rc.zig.fun/cashless-digital/?utm_campaign=fluxo360&utm_source=marketplace-zig&utm_medium=checkout-btn"

    return (
        <Modal show={isOpen} id="recharge-modal" dialogClassName="recharge-dialog-w">
            <div className='py-2 d-flex flex-column align-items-center justify-content-center gap-3 w-100'>
                <Modal.Title className='title text-center'>
                    {t("DigitalCardRecharge.accessTroughPhone")}
                </Modal.Title>
                <Modal.Body className='subtitle d-flex flex-column gap-4'>
                    <div className='d-flex flex-column gap-1'>
                        <p className='text-center'>{t("DigitalCardRecharge.modalMessageInstructionsToRecharge1")}</p>
                        <p className='text-center'>{t("DigitalCardRecharge.modalMessageInstructionsToRecharge2")}</p>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <QRCode
                            value={RECHARGE_MOBILE_URL}
                            className="qr-code-recharge-card"
                        />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Button variant='secondary' className="close-button-download-app" onClick={handleClose}>
                            {t("DownloadApp.close")}
                        </Button>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}