import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import dayjs from "dayjs";
import { PropTypes } from "prop-types";
import React, { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import sendToLogger from "../../Helpers/errorLogger";
import MaskHelper from "../../Helpers/mask";
import AuthenticationFlow from "../../components/Auth";
import Loader from "../../components/Loader";
import LoggedOutModal from "../../components/LoggedOutModal";
import SeatsCartSidebar from "../../components/SeatsCartSidebar";
import SeatsPageFooter from "../../components/SeatsFooter";
import SeatsPageHeader from "../../components/SeatsHeader";
import { useAuth } from "../../hooks/useAuth";
import NewApi from "../../services/new-api";
import "./styles.css";
import { isInZigApp } from "../../services/utils";
import { useTranslation } from "react-i18next";

const newApi = new NewApi();
const seatsRegion = process.env.REACT_APP_SEATS_REGION;
const seatsWorkspace = process.env.REACT_APP_SEATSWORKSPACE;
const currencyMask = MaskHelper.createCurrencyMask("pt-BR", "BRL");

const SeatsChart = ({
  tickets,
  onSetTickets,
  event,
  onShowCart,
  isCodeApplied,
  onAppliedCode,
  isDiscountInvalidForAll,
  isLoggedAndElegible,
}) => {
  const { isLogged } = useAuth();
  const [holdToken, setHoldToken] = useState({});
  const [show, setShow] = useState(false);
  const [totalCartValue, setTotalCartValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [seats, setSeats] = useState([]);
  const [chart, setChart] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [usedCodePromo, setUsedCodePromo] = useState("");
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isInvalidDiscount, setIsInvalidDiscount] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const selectedLanguage =
    i18n.language === "en-US" ? "en" : i18n.language === "es-ES" ? "es" : "pt";
  const urlParams = new URLSearchParams(window.location.search);
  const stewardCode = urlParams.get("code");

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  function capitalize(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  function returnSeatsConfiguration(tickets, hasDiscount, maxDiscountUse) {
    const categories = {};
    const maxSelectedObjects = [];
    let pricing = [];

    tickets.forEach((item, index) => {
      const categoryId = item.seats_category_id;

      if (!!categoryId) {
        const discountApplied = item.discount || item.discount === 0;
        let ticketType = item.name;

        if (!item.is_passport) {
          ticketType =
            item.name.split(" [")[1].split("]")[0] +
            " - " +
            item.name.split("] ")[1];
        }

        const price = discountApplied
          ? item.discount + item.fee
          : item.value + item.fee;

        const originalDescription = `${currencyMask(
          tickets[index].value
        )}(+ ${t("SeatsChart.fee")} ${currencyMask(tickets[index].fee)})`;

        const description = `${currencyMask(
          discountApplied ? item.discount : item.value
        )}(+ ${t("SeatsChart.fee")} ${currencyMask(item.fee)})`;

        const label = capitalize(ticketType.toLowerCase());

        const maxQuantity = item.maximum;

        if (!categories[categoryId]) {
          categories[categoryId] = {
            category: categoryId,
            ticketTypes: [],
          };

          maxSelectedObjects.push({
            category: categoryId,
            quantity: item.maximum,
            ticketTypes: [],
          });
        }

        categories[categoryId].ticketTypes.push({
          id: item.id,
          ticketType,
          originalPrice: discountApplied ? item.value : undefined,
          ...(discountApplied && {
            originalDescription,
          }),
          price,
          label,
          description,
          full_ticket_type: item.name,
          fee: item.fee,
          isPassport: item.is_passport,
        });

        maxSelectedObjects
          .find((obj) => obj.category === categoryId)
          .ticketTypes.push({ ticketType, quantity: maxQuantity });
      }
    });

    pricing = Object.values(categories).map(({ category, ticketTypes }) => ({
      category,
      ticketTypes,
    }));

    if (hasDiscount) {
      const priceMapping = {};
      pricing.forEach((category) => {
        category.ticketTypes.forEach((ticketType) => {
          priceMapping[ticketType.id] = {
            price: ticketType.price,
            originalDescription: ticketType.originalDescription,
            description: ticketType.description,
            fee: ticketType.fee,
          };
        });
      });

      const updatedSecondArray = seats.map((item) => {
        const { id } = item.selectedTicketTypes;
        const updatedValues =
          priceMapping[id] !== undefined
            ? priceMapping[id]
            : item.selectedTicketTypes.price;
        return {
          ...item,
          selectedTicketTypes: {
            ...item.selectedTicketTypes,
            price: updatedValues.price,
            fee: updatedValues.fee,
            originalDescription: updatedValues.originalDescription,
            description: updatedValues.description,
          },
        };
      });

      setSeats(updatedSecondArray);
    }

    maxSelectedObjects.push({
      total: maxDiscountUse || event.max_online_order_tickets,
    });

    return { pricing, maxSelectedObjects };
  }

  const handlePageComponents = async (selectedSeat) => {
    const selectedObjects = await selectedSeat.chart.listSelectedObjects();

    if (selectedObjects.length > 0 && isMobile && isOpen) {
      setIsOpen(true);
    } else if (selectedObjects.length > 0 && !isMobile) {
      setIsOpen(true);
    }

    handleTotalCartValue(selectedObjects);
  };

  async function handleSeatSelect(selectedSeat, selectedTicketTypes) {
    await handlePageComponents(selectedSeat);

    setSeats((prev) => [...prev, { selectedSeat, selectedTicketTypes }]);

    handleSeatsOnRedis(selectedSeat.chart);
  }

  const handleSeatDeselect = async (selectedSeat) => {
    await handlePageComponents(selectedSeat);

    const selectedObjects = await selectedSeat.chart.listSelectedObjects();

    setSeats((prev) => {
      const selectedSeats = [];

      for (const seat of selectedObjects) {
        selectedSeats.push({
          selectedSeat: seat,
          selectedTicketTypes: prev.find(
            (item) =>
              item.selectedSeat.id === seat.id &&
              item.selectedTicketTypes.ticketType === seat.selectedTicketType
          ).selectedTicketTypes,
        });
      }

      return selectedSeats;
    });

    handleSeatsOnRedis(selectedSeat.chart);
  };

  const handleTotalCartValue = (seats) => {
    const total = seats?.reduce((acc, seat) => {
      const selectedTicketType = seat.selectedTicketType;
      const ticket = seat.pricing.ticketTypes.find(
        (type) => type.ticketType === selectedTicketType
      );

      if (ticket) {
        return acc + ticket.price;
      }
      return acc;
    }, 0);

    setTotalCartValue(total);
  };

  const handleDiscountTickets = async (codePromo) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    await newApi
      .get(`/events/${event.slug}/tickets?d=${codePromo}`)
      .then(async (res) => {
        let cartTicketQuantity = seats.length;

        const { max_discount_use, tickets } = res.data;

        if (cartTicketQuantity > max_discount_use) {
          Swal.fire({
            title: t("Event.attention"),
            icon: "warning",
            text: `${t("SeatsChart.discountLimit")} ${max_discount_use} ${t(
              "SeatsChart.seatsLimit"
            )}`,
          });
        }

        const result = returnSeatsConfiguration(
          tickets,
          true,
          max_discount_use
        );

        setShowPromoCode(true);
        setUsedCodePromo(codePromo);
        onSetTickets(tickets);
        setIsInvalidDiscount(false);

        await chart.changeConfig(result);
      })
      .catch((err) => {
        onAppliedCode(codePromo);

        invalidDiscount();
      })
      .finally(async () => {
        const tickets = await chart.listSelectedObjects();

        handleTotalCartValue(tickets);

        setDiscountCode(codePromo);

        localStorage.setItem("code", codePromo);

        setIsLoading(false);
      });
  };

  const invalidDiscount = () => {
    setIsInvalidDiscount(true);
    Swal.fire({
      icon: "error",
      title: t("CommonExpressions.ops"),
      text: t("SeatsChart.discountApplyError"),
    });
  };

  const handleRenderChart = async (chart) => {
    if (tickets.length === 0) {
      return Swal.fire({
        icon: "error",
        title: t("SeatsChart.errorLoadingSeats"),
        text: t("SeatsChart.errorLoadingSeatsMessage"),
      }).then(() => onShowCart(false));
    }

    const { pricing, maxSelectedObjects } = returnSeatsConfiguration(tickets);

    chart.changeConfig({
      pricing,
      maxSelectedObjects,
    });
  };

  const handleChartRendered = async (chart) => {
    let selectedObjects;

    setChart(chart);

    const holdToken = JSON.parse(sessionStorage.getItem("seatsio")).holdToken;

    await newApi
      .get(`/events/${event.id}/seats/temporary-seats/${holdToken}`)
      .then((res) => {
        selectedObjects = res.data.seats;
        if (selectedObjects && selectedObjects.length >= 0) {
          chart.trySelectObjects(selectedObjects);
        }
      })
      .catch(() => { });
  };

  const handleSessionInitialized = async (holdToken) => {
    setHoldToken({ ...holdToken, created_at: dayjs() });
    setIsLoading(false);
  };

  const handleTokenExpire = () => {
    sessionStorage.removeItem("seatsio");
    setIsOpen(false);
    setSeats([]);
  };

  const handlePayment = (selectedSeats, force = false, promoCode = "") => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (!isLogged && !force) {
      setOpenLoginModal(true);
      return;
    }

    if (promoCode) {
      setUsedCodePromo(promoCode);
    }

    setIsLoading(true);

    const ticketSeat = {};
    const countOccurrences = {};

    for (const seat of selectedSeats) {
      const { id: ticketTypeId, isPassport } = seat.selectedTicketTypes;
      const { displayedLabel } = seat.selectedSeat.labels;
      const { objectType, seats } = seat.selectedSeat;

      const seatData = {
        seat_id: seat.selectedSeat.id,
        seat_name: displayedLabel,
        seat_labels: [],
      };

      if (["Table"].includes(objectType) && isPassport) {
        seatData.seat_labels = seats.map((seat) => ({
          seat_id: seat.id,
          seat_name: seat.labels.displayedLabel,
        }));
      }

      if (!ticketSeat[ticketTypeId]) {
        ticketSeat[ticketTypeId] = [];
      }

      ticketSeat[ticketTypeId].push(seatData);

      countOccurrences[ticketTypeId] =
        (countOccurrences[ticketTypeId] || 0) + 1;
    }

    const data = Object.keys(countOccurrences).map((ticketTypeId) => ({
      id: Number(ticketTypeId),
      quantity: countOccurrences[ticketTypeId],
      seat_names: ticketSeat[ticketTypeId],
    }));

    const query = {
      discount: null || promoCode || discountCode,
      steward: null || stewardCode,
      cart: data,
      ticketSeat,
      channel: isInZigApp() ? "zigapp" : "site",
      seats_session_token: holdToken.token,
      seats_session_token_created_at: holdToken.created_at,
    };

    if (data.length === 0) {
      setIsLoading(false);
      Swal.fire({
        type: "success",
        icon: "success",
        title: t("SeatsChart.loginSuccess"),
        text: t("SeatsChart.loginSuccessMessage"),
      });
      return;
    } else {
      newApi
        .post(`/events/${event.slug}/orders`, query)
        .then((res) => {
          localStorage.setItem("slug", event.slug);
          history.push(`/checkout/${res.data.token}`);
        })
        .catch((err) => {
          sendToLogger(err);
          const { type, tickets, message } = err.response.data || {};

          let errorMessage =
            type === "reach_ticket_by_document"
              ? `${t("Event.purchaseLimitReached")} ${tickets.join(", ")}.`
              : message;

          Swal.fire({
            icon: "error",
            title: t("FaleForm.oops"),
            text: errorMessage || t("Event.purchaseError"),
            didClose: () => {
              history.push(`/eventos/${event.slug}`);
            },
          });
        })
        .finally(() => {
          sessionStorage.removeItem("seatsio");
          setIsLoading(false);
        });
    }
  };

  const handleSeatsOnRedis = async (chart) => {
    const holdToken = JSON.parse(sessionStorage.getItem("seatsio"))?.holdToken;

    if (!holdToken) {
      return;
    }

    const selectedObjects = await chart.listSelectedObjects();

    const selectedSeats = selectedObjects.map((seat) => ({
      id: seat.id,
      ticketType: seat.selectedTicketType,
      amount: 1,
    }));

    const data = { hold_token: holdToken, seats: selectedSeats };

    newApi.post(`events/${event.id}/seats/temporary-reserve`, data);
  };

  const onClose = () => {
    setShow(false);
  };

  const onCloseModalLogin = () => {
    setOpenLoginModal(false);
  };

  return (
    <Fragment>
      <LoggedOutModal
        show={show}
        onHide={onClose}
        expiresIn={holdToken.expiresAt}
        onShowCart={onShowCart}
        timeToExpire={holdToken.expiresInSeconds}
        onLoginClicked={setOpenLoginModal}
      />
      {isLoading && (
        <>
          <div className="loading-container"></div>
          <Loader title={t("Index.wait")}></Loader>
        </>
      )}
      <div className="content-wrapper" key="wrapper">
        <SeatsPageHeader
          event={event}
          holdToken={holdToken}
          isOpen={isOpen}
          onOpen={setIsOpen}
          onShowCart={onShowCart}
        />

        <Container fluid className="p-0">
          <div
            id="seats-render"
            className={`seats-render ${!isMobile && isOpen && seats?.length
              ? "w-md-835"
              : "w-100 w-lg-100 w-md-100"
              }`}
          >
            <SeatsioSeatingChart
              showMinimap
              showSeatLabels
              event={event.seats_integration_id}
              workspaceKey={seatsWorkspace}
              language={selectedLanguage}
              messages={{
                confirm: t("CommonExpressions.confirm"),
              }}
              region={seatsRegion}
              session="continue"
              divId="seats-render"
              mode="normal"
              priceFormatter={(price) => currencyMask(price)}
              onRenderStarted={(chart) => handleRenderChart(chart)}
              onChartRenderingFailed={(chart) => chart.startNewSession()}
              onChartRendered={async (chart) =>
                await handleChartRendered(chart)
              }
              onObjectSelected={(seat, ticketTypes) =>
                handleSeatSelect(seat, ticketTypes)
              }
              onObjectDeselected={(seat) => handleSeatDeselect(seat)}
              onSessionInitialized={async (holdToken) =>
                await handleSessionInitialized(holdToken)
              }
              onHoldTokenExpired={handleTokenExpire}
            />
          </div>
        </Container>
        <SeatsCartSidebar
          seats={seats}
          isOpen={isOpen}
          onClose={setIsOpen}
          isLoggedAndElegible={isLoggedAndElegible}
        />
      </div>

      <SeatsPageFooter
        totalCartValue={totalCartValue}
        seats={seats}
        eventInfo={event}
        onApplyClick={handleDiscountTickets}
        onLoading={setIsLoading}
        isLoading={isLoading}
        isMobile={isMobile}
        onPayment={handlePayment}
        onOpenSidebar={setIsOpen}
        sidebarOpen={isOpen}
        showPromoCode={showPromoCode}
        setShowPromoCode={setShowPromoCode}
        usedCodePromo={usedCodePromo}
        isCodeApplied={isCodeApplied}
        onAppliedCode={onAppliedCode}
        tickets={tickets}
        isDiscountInvalidForAll={isDiscountInvalidForAll}
        isInvalidDiscount={isInvalidDiscount}
        event={event}
      />

      <AuthenticationFlow
        show={openLoginModal}
        nextToUrl={window.location.href}
        onClose={onCloseModalLogin}
        onLoginCallback={() => handlePayment(seats, true)}
      />
    </Fragment>
  );
};

SeatsPageFooter.propTypes = {
  tickets: PropTypes.array,
  event: PropTypes.object,
  onShowCart: PropTypes.func,
};

export default SeatsChart;
