import React from "react";
import "./style.css";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/loading.json";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

NewLoading.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default function NewLoading({ showModal, title, subtitle }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Modal id="new-loading-modal" show={showModal}>
      <Modal.Body className="new-loading-modal-body d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center new-loading-container">
          <Lottie isClickToPauseDisabled options={defaultOptions} height={120} width={120} />
          <p className="mt-4">{title}</p>
          {subtitle && <p className="mt-2" id="new-loading-subtitle">{subtitle}</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
}
