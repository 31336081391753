import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Container from "./components/Container";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useAuth } from "./hooks/useAuth";
import Auth from "./pages/Auth";
import CheckoutNew from "./pages/CheckoutNew";
import ProcessingCheckout from "./pages/Compras/ProcessingCheckout";
import ResultCheckoutPage from "./pages/Compras/ResultPage";
import Event from "./pages/Event";
import EventSeatMap from "./pages/EventSeatMap";
import Locais from "./pages/Eventos-local";
import Produtor from "./pages/Eventos-produtor";
import Faqs from "./pages/FaleDuvidas/FaleFaqs";
import FaleForm from "./pages/FaleDuvidas/FaleForm";
import FaleMain from "./pages/FaleDuvidas/FaleMain";
import FaqExplanation from "./pages/FaleDuvidas/FaqExplanation";
import Index from "./pages/Index";
import NominalTransfer from "./pages/NominalTransfer";
import "./pages/Not-Found/index.css";
import ResetPassword from "./pages/ResetPassword";
import SearchResults from "./pages/SearchResults";
import SocialAuth from "./pages/SocialAuth";
import CookiesTerms from "./pages/Terms/Terms-Cookies";
import MeiaEntrada from "./pages/Terms/Terms-Discount";
import NossasPoliticas from "./pages/Terms/Terms-Politic";
import PrivacyTerms from "./pages/Terms/Terms-Privacy";
import Termos from "./pages/Terms/Terms-Use";
import MeusEventos from "./pages/Usuario/Meus-eventos";
import MeusIngressos from "./pages/Usuario/Meus-ingressos";
import MeusPedidos from "./pages/Usuario/Meus-pedidos";
import Perfil from "./pages/Usuario/Perfil";
import Aplicativos from "./pages/aplicativos";
import { isInZigApp } from "./services/utils";

const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

export default function Routes() {
  const { isLogged, isInitialized } = useAuth();

  const requiredLogin = (to, _from, next) => {
    if (to.meta.auth && isInitialized) {
      next();
    } else {
      next.redirect(
        "/entrar?next=" + to.location.pathname + to.location.search
      );
    }
  };

  return (
    <BrowserRouter>
      {!isInZigApp() && <Header />}

      <Switch>
        <Container>
          <Route path="/" exact component={Index} />
          <Route path="/cadastro" exact component={Index} />
          <Route path="/a/:provider" exact component={SocialAuth} />

          <Route path="/entrar" exact component={Auth} />
          <Route path="/eventos" exact component={SearchResults} />

          <Route path="/localidades/:zip_code" component={Locais} />
          <Route path="/produtores/:id" component={Produtor} />
          <Route path="/organizacoes/:id" component={Produtor} />

          <Route path="/eventos/produtor/:id/:type" component={Produtor} />
          <Route path="/eventos/:slug" component={Event} />

          <Route
            path="/mapa-assentos/:event_id"
            exact
            component={EventSeatMap}
          />

          <Route path="/termos" component={Termos} />
          <Route path="/meia-entrada" component={MeiaEntrada} />
          <Route path="/validatoken/:token" component={ResetPassword} />

          <Route path="/politicas" component={NossasPoliticas} />
          <Route path="/privacidade" component={PrivacyTerms} />
          <Route path="/cookies" component={CookiesTerms} />

          {isWhitelabel && (
            <>
              <Route path="/fale-conosco" component={FaleMain} />
              <Route path="/fale-form/" component={FaleForm} />
            </>
          )}

          <Route path="/faq" exact component={Faqs} />
          <Route path="/faqs" exact component={Faqs} />
          <Route path="/faqs/:id" component={FaqExplanation} />

          <Route path="/aplicativos" component={Aplicativos} />

          <GuardProvider guards={[requiredLogin]}>
            <GuardedRoute
              path="/perfil"
              component={Perfil}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/meus-ingressos"
              component={MeusIngressos}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/transferencia-nominal"
              component={NominalTransfer}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/meus-eventos"
              component={MeusEventos}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/meus-pedidos"
              component={MeusPedidos}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/processing"
              component={ProcessingCheckout}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/checkout/:token"
              component={CheckoutNew}
              meta={{ auth: isLogged }}
            />

            <GuardedRoute
              path="/status/:token/checkout/:status"
              component={ResultCheckoutPage}
              meta={{ auth: isLogged }}
            />
            <GuardedRoute
              path="/processing/:token"
              component={ProcessingCheckout}
              meta={{ auth: isLogged }}
            />
          </GuardProvider>
        </Container>
      </Switch>
      {!isInZigApp() && <Footer />}
    </BrowserRouter>
  );
}
