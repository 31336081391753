import { Col, Row } from "react-bootstrap";
import { groupBy } from "../../services/utils";
import TicketInfoView from "./TicketInfoView";
import MaskHelper from "../../Helpers/mask";
import { calculateValueDiscount } from "../../Helpers/checkout";
import React, { Fragment, useMemo } from "react";
import { objectify, unique } from "../../Helpers/radash";
import { useTranslation } from "react-i18next";

const TicketsListView = ({
  tickets,
  singleLine = false,
  groupByName = false,
  addTicketFee = true,
  discountInfo,
  showPriceDetails = true,
  showTicketDivider = true,
  showPassportDetails = true,
}) => {
  const { t } = useTranslation();
  const indexedTickets = useMemo(
    () => groupBy(tickets || [], ({ passport_index }) => passport_index),
    [tickets]
  );

  const passportNamedTickets = useMemo(
    () => groupBy(tickets || [], ({ passport_name }) => passport_name),
    [tickets]
  );

  const typeTickets = useMemo(
    () =>
      groupBy(
        tickets || [],
        ({ passport_id, ticket_type_id }) => passport_id || ticket_type_id
      ),
    [tickets]
  );

  const passportValues = useMemo(
    () =>
      objectify(
        Array.from(indexedTickets.entries()).map(([key, values]) => ({
          key,
          ...values.reduce((acc, item) => ({
            fee: acc.fee + item.fee,
            value: acc.value + item.value,
          })),
        })),
        ({ key }) => key
      ),
    [indexedTickets]
  );

  const itemsClassName = useMemo(
    () =>
      `order-items  ${
        showTicketDivider ? "" : "order-items-without-divider pt-0 pb-1"
      }`,
    [showTicketDivider]
  );

  return (
    <>
      {Array.from((groupByName ? typeTickets : indexedTickets).entries()).map(
        ([_, values], idx) => (
          <Col xs={12} key={idx} className={itemsClassName}>
            {!!values[0].passport_id && (
              <>
                <Row>
                  <Col
                    xs={8}
                    className={singleLine ? "order-items-fixed-line" : ""}
                  >
                    <span
                      className={showPriceDetails ? "font-weight-bold" : ""}
                    >
                      {groupByName
                        ? `${
                            unique(
                              passportNamedTickets
                                .get(values[0].passport_name)
                                .map(({ passport_index }) => passport_index)
                            ).length
                          }x `
                        : ""}
                      {values[0].passport_name}
                    </span>

                    {showPriceDetails && (
                      <>
                        <br />

                        {passportValues[values[0].passport_index].value === 0 &&
                        passportValues[values[0].passport_index].fee === 0 ? (
                          <span>{t("TicketInfoView.free")}</span>
                        ) : (
                          <Fragment>
                            {passportValues[values[0].passport_index].fee >
                              0 && (
                              <span>
                                {MaskHelper.numberToLocaleCurrency(
                                  passportValues[values[0].passport_index].value
                                ) + " "}
                                ( {t("TicketInfoView.fee")}
                                {MaskHelper.numberToLocaleCurrency(
                                  passportValues[values[0].passport_index].fee
                                )}
                                )
                              </span>
                            )}
                          </Fragment>
                        )}
                      </>
                    )}
                  </Col>

                  <Col xs={4} className="text-right">
                    {passportValues[values[0].passport_index].value === 0 &&
                    passportValues[values[0].passport_index].fee === 0
                      ? t("TicketInfoView.free")
                      : MaskHelper.numberToLocaleCurrency(
                          discountInfo
                            ? calculateValueDiscount(
                                discountInfo.type,
                                discountInfo.value,
                                passportValues[values[0].passport_index].value +
                                  (addTicketFee
                                    ? passportValues[values[0].passport_index]
                                        .fee
                                    : 0)
                              )
                            : passportValues[values[0].passport_index].value +
                                (addTicketFee
                                  ? passportValues[values[0].passport_index].fee
                                  : 0)
                        )}
                  </Col>
                </Row>

                {showPassportDetails && (
                  <Row className="pl-2 mt-2">
                    {Array.from(
                      groupBy(values, ({ name }) => name).values()
                    ).map((items, idx) => (
                      <Col
                        xs={12}
                        key={idx}
                        className="order-items order-items-without-divider py-0"
                      >
                        <span>
                          &#x2022; {items.length}x {items[0].name}
                        </span>
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )}

            {!values[0].passport_id && (
              <>
                {(groupByName
                  ? Array.from(
                      groupBy(
                        values,
                        ({ ticket_type_id }) => ticket_type_id
                      ).values()
                    )
                  : values
                ).map((items, idx) => (
                  <TicketInfoView
                    key={idx}
                    ticket={groupByName ? items[0] : items}
                    quantity={groupByName ? items.length : 1}
                    singleLine={singleLine}
                    discountInfo={discountInfo}
                    showDivider={showTicketDivider}
                    addTicketFee={addTicketFee}
                    showQuantity={groupByName}
                    showPriceDetails={showPriceDetails}
                  />
                ))}
              </>
            )}
          </Col>
        )
      )}
    </>
  );
};

export default TicketsListView;
