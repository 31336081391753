import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Copy } from "../../../../../assets/svg/Copy.svg";
import { ReactComponent as Checked } from "../../../../../assets/svg/new_check.svg";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";

function PixSection({ handleCopy, isCopy, handlePayment }) {
  const { t } = useTranslation();
  const [showPaidButton, setShowPaidButton] = useState(false)

  useEffect(() => {
    const interval = setInterval(async () => {
      setShowPaidButton(true)
    }, 40000);

    return () => clearInterval(interval);
  }, [])
  return (
    <Fragment>
      <div className="row d-flex justify-content-center align-items-center w-100 gap-2">
        <NewButton
          className="col-md-6 col-12 w-sm-100 primary-contained pix-btn pix-copy-btn"
          onClick={handleCopy}
          content={
            <Fragment>
              {isCopy ? (
                <Fragment>
                  <Checked /> {t("CheckoutStatus.buttons.pixCopiedCode")}
                </Fragment>
              ) : (
                <Fragment>
                  <Copy stroke="white" /> {t("CheckoutStatus.buttons.pixCopyCode")}
                </Fragment>
              )}
            </Fragment>
          }
        />

        {showPaidButton && (
          <NewButton
            className="col-md-6 col-12 w-sm-100 pix-btn secondary-contained"
            onClick={handlePayment}
            content={
              <Fragment>
                {t("CheckoutStatus.common.paymentDone")}
              </Fragment>
            }
          />
        )}
      </div>
    </Fragment>
  );
}

export default PixSection;
